import React from "react";
import { graphql, PageProps } from "gatsby";

import { PageLayout } from "src/components/layout/PageLayout";
import { Wysiwyg } from "src/components/Wysiwyg";

interface PrivacyPolicyPageProps extends PageProps {
  data: GatsbyTypes.PrivacyPolicyPageQuery;
}

const PrivacyPolicyPage: React.FC<PrivacyPolicyPageProps> = ({ data }) => {
  const pageContent = data.strapiPrivacyPolicy?.content;

  return (
    <PageLayout title="Privacy Policy" width="normal">
      {pageContent && (
        <Wysiwyg content={pageContent} />
      )}
    </PageLayout>
  );
};

export default PrivacyPolicyPage;

export const query = graphql`
  query PrivacyPolicyPage {
    strapiPrivacyPolicy {
      content
    }
  }
`;
